import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { enqueueSnackbar } from "./notifier";
import { Slide } from "@mui/material";
import { SummarizingFn } from "../../utilities/utilitiesFun";

const initialState = {
  summaryText: "",
  statusSummary: "idle",
  error: null,
  menuIcon: "",
  Ad: {},
  statusAds:'idle',
  AdBlockerStatus:false
};

const api = process.env.REACT_APP_API_URL;

//   return response.data.

export const fetchAISummary = createAsyncThunk(
  "summary/fetchAISummary ",
  async ({ sentencNb, text }, thunkAPI) => {
    let data;
    try {
      const response = await axios.post(`${api}RapidApi/summary`, {
        sentencNb,
        text,
      });
      data = await response.data;
      if (data) {
        return SummarizingFn(data.sentences);
      }
      throw new Error(response.statusText);
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: "Request failed",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            TransitionComponent: Slide,
          },
        })
      );

      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const fetchAd = createAsyncThunk(
  "summary/fetchAd",
  async (type, thunkAPI) => {
    let data;
    try {
      const response = await axios.get(
        `${api}RapidApi/getAdsByActivity/authNot?active=true`
      
      );
      data = await response.data;
      if (response.status === 200) {
        return data.data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const fetchAdBlockerStatus = createAsyncThunk('summary/fetchAdBlockerStatus', async (type, thunkAPI) => {
  let data;
  try {
    const response = await axios.get(`${api}RapidApi/getAdblocker/status`);
    data = await response.data;
    if (response.status === 200) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    console.log(err);
    return Promise.reject(err.message ? err.message : data?.message);
  }
});

const slice = createSlice({
  name: "summary ",
  initialState,
  reducers: {
    reset: (state) => {
      return {
        summaryText: "",
        statusSummary: "idle",
        error: null,
        menuIcon: state.menuIcon,
        Ad: state.Ad 
      };
    },
    getMenuIcons: (state, action) => {
      return { ...state, menuIcon: action.payload };
    },
  },
  extraReducers: {
    [fetchAISummary.pending]: (state, action) => {
      state.statusSummary = "loading";
    },
    [fetchAISummary.fulfilled]: (state, action) => {
      state.statusSummary = "succeeded";
      state.summaryText = action.payload;
    },
    [fetchAISummary.rejected]: (state, action) => {
      state.statusSummary = "failed";
      state.error = action.payload;
    },

    [fetchAd.pending]: (state, action) => {
    state.statusAds = "loading";
    },
    [fetchAd.fulfilled]: (state, action) => {
      state.statusAds = "succeeded";
      state.Ad = action.payload;
    },
    [fetchAd.rejected]: (state, action) => {
      state.statusAds = "failed";
      state.error = action.payload;
    },
    [fetchAdBlockerStatus.pending]: (state, action) => {
      state.getStatus = 'loading';
    },
    [fetchAdBlockerStatus.fulfilled]: (state, action) => {
      state.getStatus = 'succeeded';
      state.AdBlockerStatus = action.payload.adBlockerDetected;
    },
    [fetchAdBlockerStatus.rejected]: (state, action) => {
      state.getStatus = 'failed';
      state.error = action.payload;
    }
  },
});

export const reducer = slice.reducer;

export const resetSummary = () => (dispatch) => {
  dispatch(slice.actions.reset());
};
export const getMenuIcons = (icon) => (dispatch) => {
  dispatch(slice.actions.getMenuIcons(icon));
};
export default slice;
