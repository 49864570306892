import { Button, TextField } from "@mui/material";
import React, { useState } from "react";
import {
  BorderButton,
  CheckButton,
  NoBorderButton,
  RegenerateButton,
  StyledTextField,
  UploadButton,
} from "../StyledComponenets";
import upload from "../../assets/img/upload.svg";
import copy from "../../assets/img/copy.svg";
import copyDone from "../../assets/img/copyDone.svg";

import download from "../../assets/img/download.svg";
import { useTranslation } from "react-i18next";
import SumuraizeModal from "../SumuraizeModal";
import mammoth from "mammoth";
import { readPDFFile } from "../../utilities/utilitiesFun";
import { useSelector } from "react-redux";

const DoubleInput = ({
  rightInput,
  summary,
  onSummary,
  placeholderTitle,
  handleChangLeftInput,
  leftInput,
  onCheck,
  setLeftInput,
  setSentencNb,
  sentencNb,

}) => {
  const [copied, setCopied] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [open, setOpen] = React.useState(false);
  const { menuIcon } = useSelector((state) => state.summary);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSentencNb("");
  };
  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    const textType = file?.name.split(".")[file?.name.split(".").length - 1];
    setUploadedFile(file);
    const reader = new FileReader();

    if (file && textType === "docx") {
      reader.onload = (e) => {
        const fileContent = e.target.result;
        mammoth
          .extractRawText({ arrayBuffer: fileContent })
          .then((result) => {
            const extractedText = result.value;
            setLeftInput(extractedText);
          })
          .catch((error) => {
            console.error("Error extracting text from Word file:", error);
          });
      };

      reader.readAsArrayBuffer(file);
    } else if (file && textType === "pdf") {
      try {
        const pdfText = await readPDFFile(file);
        setLeftInput(pdfText);
      } catch (error) {
        console.error("Error reading PDF file:", error);
      }
    } else {
      reader.onload = (e) => {
        const text = e.target.result;
        setLeftInput(text);
      };

      reader.readAsText(file);
    }
  };

  const handleDownloadText = () => {
    const blob = new Blob([rightInput], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "myTextFile.txt";
    a.click();
    URL.revokeObjectURL(url);
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(rightInput);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    } catch (error) {
      console.error("Unable to copy text:", error);
    }
  };
  const { t } = useTranslation();
  return (
    <div className="input-content">
      <div className="first-input">
        <StyledTextField
          value={leftInput}
          onChange={(e) => handleChangLeftInput(e)}
          placeholder={`${t(
            "Enter your text or upload a document for an AI-powered"
          )} ${t(placeholderTitle)}.`}
          variant="standard"
          inputProps={{
            style: {
              overflow: "visible",
              height: "310px",
              padding: "20px",
            },
          }}
          multiline
        />
        <div className="upload-content">
          <UploadButton
            onClick={() => document.getElementById("fileInput").click()}
          >
            <input
              type="file"
              id="fileInput"
              accept=".txt, .doc, .docx, .pdf"
              style={{ display: "none" }}
              onChange={handleFileUpload}
            />
            <img
              src={upload}
              width={22}
              height={22}
              style={{ marginRight: "10px" }}
            />
            <span> {t("Upload Doc")} </span>
          </UploadButton>

          <CheckButton
            onClick={summary ? handleClickOpen : onCheck}
            disabled={leftInput == null || leftInput == ""}
          >
            {" "}
            <img
              style={{ marginRight: "10px" }}
              src={`${menuIcon}`}
              width={20}
              height={20}
            />
            <span>{t("Check")}</span>
          </CheckButton>
        </div>
        <div className="word-lenght-content">
          <div>
            <span className="word-lenght">{`${t("Characters")} ${
              !leftInput ? 0 : leftInput?.length
            } ${t("Words")} ${
              !leftInput ? 0 : leftInput?.trim().split(/\s+/).length
            }`}</span>
          </div>
  
          {/* {summary && (
            <BorderButton
              disabled={leftInput == null || leftInput == ""}
              onClick={handleClickOpen}
            >
              {t("Summary")}
            </BorderButton>
          )} */}
        </div>
      </div>
      <div className="first-input">
        <StyledTextField
          value={rightInput}
          //onChange={handleChangeRightInput}

          placeholder={t("Results are shown here...")}
          variant="standard"
          inputProps={{
            style: {
              overflow: "visible",
              height: "310px",
              padding: "20px",
            },
          }}
          multiline
        />
        <div className="download-content">
          <NoBorderButton onClick={handleDownloadText}>
            <img
              style={{ marginRight: "10px" }}
              src={download}
              width={20}
              height={20}
            />{" "}
            <span>{t("Download")}</span>
          </NoBorderButton>
          <NoBorderButton onClick={handleCopy}>
            <img
              style={{ marginRight: "10px" }}
              src={copied ? copyDone : copy}
              width={20}
              height={20}
            />
            <span> {t("Copy")}</span>
          </NoBorderButton>
        </div>
      </div>
      {open && (
        <SumuraizeModal
          setSentencNb={setSentencNb}
          open={open}
          handleClose={handleClose}
          onSummary={onSummary}
          setOpen={setOpen}
          sentencNb={sentencNb}
        />
      )}
    </div>
  );
};

export default DoubleInput;
