import React from "react";
import { useTranslation } from "react-i18next";
const Conditions = () => {
  const { t } = useTranslation();

  return (
    <div
      className="condition-container"
      style={{ padding: "50px", textAlign: "left" }}
    >
      <h2>1- {t("Presentation of the Online Tool")}</h2>
      <p>
        {t(
          "Pursuant to Article 6 of Law No. 2004-575 of 21 June 2004 on confidence in the digital economy, users of the smartwriting-ai.com application are informed of the identity of the various parties involved in its creation and monitoring:"
        )}
      </p>
      <ul>
        <li>{t("Owner: AlaEcom – France")}</li>
        <li>{t("Application: smartwriting-ai.com")}</li>
        <li>{t("Publication Manager: AlaEcom – support@a2h-tech.com")}</li>
      </ul>

      <h2>
        2- {t(
          "General Terms and Conditions of Use of the Tool and Services Offered"
        )}
      </h2>
      <p>
        {t(
          "Using the smartwriting-ai.com tool implies full and complete acceptance of the terms and conditions of use described below. These terms of use may be modified or supplemented at any time, so users are advised to check them regularly."
        )}
      </p>

      <h2>3- {t("Description of the Services Provided")}</h2>
      <p>
        {t(
          "smartwriting-ai.com offers four distinct services: summarization, rephrasing, grammar correction, and translation. Each user is responsible for the content they submit for processing."
        )}
      </p>

      <h2>4- {t("Contractual Limitations on Technical Data")}</h2>
      <p>
        {t(
          "smartwriting-ai.com uses the following technologies: Reactjs, Html, Nodejs, Css. smartwriting-ai.com cannot be held responsible for material damage related to the use of the site. Furthermore, the site user agrees to access the site using recent, virus-free equipment and an updated, latest-generation browser."
        )}
      </p>

      <h2>5- {t("Limitations of Liability")}</h2>
      <p>
        {t(
          "smartwriting-ai.com disclaims all liability concerning copyright related to texts processed by the site's services. The tool only provides text processing features acting on the submitted data."
        )}
      </p>

      <h2>6- {t("Personal Data Management in Compliance with the GDPR")}</h2>
      <p>
        <strong>{t("Data Collection and Use:")}</strong>{" "}
        {t(
          "No personal data is stored when using the services, except for the data necessary for communication via the contact page. This data is exclusively used to respond to the user and is not stored in the long term."
        )}
      </p>
      <p>
        <strong>{t("User Rights:")}</strong>{" "}
        {t(
          "In accordance with the GDPR, every user has the right to access, correct, and delete their personal data. You can exercise this right by contacting support@a2h-tech.com."
        )}
      </p>
      <p>
        <strong>{t("Data Transfer and Sharing:")}</strong>{" "}
        {t("No personal data is shared or transferred to third parties.")}
      </p>

      <h2>7- {t("Cookies")}</h2>
      <p>
        {t(
          "Browsing on smartwriting-ai.com may result in the installation of cookies on the user's device. These cookies are used to enhance user experience and gather navigation statistics. No cookie is used for advertising or profiling purposes."
        )}
      </p>

      <h2>8- {t("Applicable Law and Jurisdiction")}</h2>
      <p>
        {t(
          "Any dispute related to the use of smartwriting-ai.com is subject toFrench law. Exclusive jurisdiction is given to the competent courts of Paris."
        )}
      </p>
    </div>
  );
};

export default Conditions;
