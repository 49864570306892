import translationIcon from "../assets/img/translationIcon.svg";
import paraphraseIcon from "../assets/img/paraphraseIcon.svg";
import SummaryIcon from "../assets/img/SummaryIcon.svg";
import spellIcon from "../assets/img/spellIcon.svg";

import usa from "../assets/flags/usa.svg";
import france from "../assets/flags/france.svg";
import spain from "../assets/flags/spain.svg";
import german from "../assets/flags/german.svg";
import portugal from "../assets/flags/portugal.svg";
import italy from "../assets/flags/italy.svg";
import japan from "../assets/flags/japan.svg";
import china from "../assets/flags/china.svg";
import greece from "../assets/flags/greece.svg";
import saudi from "../assets/flags/saudi.svg";
import russia from "../assets/flags/russia.svg";
import turkey from "../assets/flags/turkey.svg";
import Vietnam from "../assets/flags/vietnam.svg";
import sweden from "../assets/flags/sweden.svg";
import poland from "../assets/flags/poland.svg";
import iran from "../assets/flags/iran.svg";
import india from "../assets/flags/india.svg";
import hungary from "../assets/flags/hungary.svg";
import indonesia from "../assets/flags/indonesia.svg";
import south from "../assets/flags/south.svg";


export const menuItems = [
  { title: "Summary", icon: SummaryIcon },
  { title: "Translation", icon: translationIcon },
  { title: "Spelling Correction", icon: spellIcon },
  { title: "Paraphrase", icon: paraphraseIcon },
];

export const languages = [
  { label: "English", code: "en", icon: usa },
  { label: "French", code: "fr", icon: france},
  { label: "Spanish", code: "spa", icon: spain },
];

export const AppLanguages = [
  { label: "English", code: "en",icon: usa},
  { label: "French", code: "fr",icon: france},
  { label: "Spanish", code: "es",icon: spain },
  { label: "German", code: "de",icon: german },
];


export const localesArray = [
  { code: 'en-US', label: 'English', icon: usa, codeDetected: 'eng' },
  { code: 'fr-FR', label: 'French', icon: france, codeDetected: 'fra' },
  { code: 'de-DE', label: 'German', icon: german, codeDetected: 'deu' },
  { code: 'pt-PT', label: 'Portuguese', icon: portugal, codeDetected: 'por' },
  { code: 'it-IT', label: 'Italian', icon: italy, codeDetected: 'ita' },
  { code: 'es-ES', label: 'Spanish', icon: spain, codeDetected: 'spa' },
  { code: 'ja-JP', label: 'Japanese', icon: japan, codeDetected: 'jpn' },
  { code: 'zh-CN', label: 'Chinese', icon: china, codeDetected: 'cmn' },
];

export const TransaltionArray = [
  { code: 'en', label: 'English', icon: usa, codeDetected: 'eng' },
  { code: 'fr', label: 'French', icon: france, codeDetected: 'fra' },
  { code: 'de', label: 'German', icon: german, codeDetected: 'deu' },
  { code: 'pt', label: 'Portuguese', icon: portugal, codeDetected: 'por' },
  { code: 'it', label: 'Italian', icon: italy, codeDetected: 'ita' },
  { code: 'es', label: 'Spanish', icon: spain, codeDetected: 'spa' },
  { code: 'ja', label: 'Japanese', icon: japan, codeDetected: 'jpn' },
  { code: 'zh-CN', label: 'Chinese', icon: china, codeDetected: 'cmn' },
  { code: 'el', label: 'Greek', icon: greece, codeDetected: 'ell' },
  { code: 'ar', label: 'Arabic', icon: saudi, codeDetected: 'arb' },
  { code: 'ru', label: 'Russian', icon: russia, codeDetected: 'rus' },
  { code: 'tr', label: 'Turkish', icon: turkey, codeDetected: 'tur' },
  { code: 'vi', label: 'Vietnamese', icon: Vietnam, codeDetected: 'vie' },
  { code: 'sv', label: 'Swedish', icon: sweden, codeDetected: 'swe' },
  { code: 'pl', label: 'Polish', icon: poland, codeDetected: 'pol' },
  { code: 'fa', label: 'Persian', icon: iran, codeDetected: 'pes' },
  { code: 'hi', label: 'Hindi', icon: india, codeDetected: 'hin' },
  { code: 'hu', label: 'Hungarian', icon: hungary, codeDetected: 'hun' },
  { code: 'id', label: 'Indonesian', icon: indonesia, codeDetected: 'ind' },
  { code: 'ko', label: 'Korean', icon: south, codeDetected: 'kor' },
];

export const SummaryArray = [
  { code: 'en', label: 'English', icon: usa, codeDetected: 'eng' },
  { code: 'fr', label: 'French', icon: france, codeDetected: 'fra' },
  { code: 'de', label: 'German', icon: german, codeDetected: 'deu' },
  { code: 'pt', label: 'Portuguese', icon: portugal, codeDetected: 'por' },
  { code: 'it', label: 'Italian', icon: italy, codeDetected: 'ita' },
  { code: 'es', label: 'Spanish', icon: spain, codeDetected: 'spa' },
  { code: 'el', label: 'Greek', icon: greece, codeDetected: 'ell' },
  { code: 'ar', label: 'Arabic', icon: saudi, codeDetected: 'arb' },
];