import React, { useEffect, useState } from "react";
import {
  ServiceDescription,
  ServiceInput,
  ServicesShapes,
} from "../../../components";
import spellingCorrectionImage from "../../../assets/img/spellingCorrection.svg";

import { useTranslation } from "react-i18next";
import { fetchAISpellingCorrection, resetSpellingCorrecton } from "../../../redux/slices/spellingCorrection";
import { useDispatch, useSelector } from "react-redux";
const SpellingCorrection = (spelling) => {
  const { t } = useTranslation();
  const [leftInput, setLeftInput] = useState("");
  const [rightInput, setRightInput] = useState("");
const dispatch = useDispatch()
  const { spellingCorrection, statusSpelling, error } = useSelector(
    (state) => state.spellingCorrection
  );
  useEffect(() => {
    return () => {
      dispatch(resetSpellingCorrecton())
      setRightInput("");
    };
  }, []);

  return (
    <div
      div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
      }}
    >
      <ServiceInput
        leftInput={leftInput}
        setLeftInput={setLeftInput}
        rightInput={rightInput}
        setRightInput={setRightInput}
        placeholderTitle={"Spelling Correction"}
        paraphrase={true}
        spelling={true}
        title={t("Spelling Correction")}
        check={fetchAISpellingCorrection}
        textResult={spellingCorrection}
        status={statusSpelling}
      />
      <ServicesShapes />

      <div className="rounded-gray-shape"></div>
      <ServiceDescription
        descriptionImage={spellingCorrectionImage}
        descriptionTitle={t("Spelling Correction")}
        description={t(
          "Utilize our grammar and spell correction service, empowered by advanced AI technology, which swiftly examines your text, identifies errors, and provides precise corrections, allowing you to save valuable time and enhance accuracy."
        )}
      />
    </div>
  );
};
export default SpellingCorrection;
