import React, { useState } from "react";
import emailjs from "emailjs-com";
import { Slide, styled } from "@mui/material";
import zIndex from "@mui/material/styles/zIndex";
import { enqueueSnackbar } from "../../redux/slices/notifier";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const FormContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
minHeight:'100vh',
  marginTop:"30px"
}));

const StyledInput = styled("input")(() => ({
  width: "100%",
  padding: "10px",
  marginBottom: "15px",
  border: "1px solid #ccc",
  borderRadius: " 5px",
  zIndex: "10",
}));

const StyledTextArea = styled("textarea")(() => ({
  width: "100%",
  padding: "10px",
  marginBottom: "15px",
  border: "1px solid #ccc",
  borderRadius: "5px",
  height: "350px",
}));

const StyledButton = styled("button")(() => ({
  backgroundColor: "#024180",
  color: "white",
  border: "none",
  borderRadius: "5px",
  padding: "10px 20px",
  cursor: "pointer",
}));

function ContactForm() {
  const {t } =useTranslation()
  const [contactData, setContactData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_EMAIL,
        process.env.REACT_APP_TEMPLETE_EMAIL,
        e.target,
        process.env.REACT_APP_USER_ID_EMAIL
      )
      .then(
        (response) => {
          dispatch(
            enqueueSnackbar({
              message: "Email sent successfully!",
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "success",
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center",
                },
                TransitionComponent: Slide,
              },
            })
          );
        },
        (error) => {
          dispatch(
            enqueueSnackbar({
              message: "Email sending failed!",
              options: {
                key: new Date().getTime() + Math.random(),
                variant: "error",
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center",
                },
                TransitionComponent: Slide,
              },
            })
          );
        }
      );
  };

  return (
    <FormContainer>
      <h2 style={{ color: "white" }}>{t('Contact Us')}</h2>
      <form onSubmit={handleSubmit}>
        <StyledInput
          type="text"
          name="from_name"
          placeholder={t("Your Name")}
          required
        />
        <StyledInput
          type="email"
          name="reply_to"
          placeholder={t("Your Email")}
          required
        />
        <StyledTextArea
          name="message"
          placeholder={t("Your Message")}
        
          required
        />
        <StyledButton type="submit">{t("Send Message")}</StyledButton>
      </form>
    </FormContainer>
  );
}

export default ContactForm;
