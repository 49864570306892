import React from "react";
import { Button, TextField, styled } from "@mui/material";

export const StyledTextField = styled(TextField)(() => ({
  width: "100%",
  background: "white",
  borderTopRightRadius: "20px",
  borderTopLeftRadius: "20px",
  "& .MuiInput-underline:before": {
    borderBottom: "none", // Remove the underline when not focused
  },
  "& .MuiInput-underline:after": {
    borderBottom: "none", // Remove the underline when focused
  },
  "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
    borderBottom: "none", // Remove the underline on hover
  },
}));

export const UploadButton = styled(Button)(() => ({
  display: "flex",
  alignItems: "center",
  textTransform: "none",
  color: "#2F3237",
  fontSize: "14px",
  "&:hover": { backgroundColor: "#239AFF" },
  "&:disabled": { backgroundColor: "#dddddd", color: "white" },
  background: "#239AFF",
  color: "white",
  fontSize: "10px",
  width: "144px",
  borderBottomLeftRadius: "10px",
}));

export const CheckButton = styled(Button)(() => ({
  display: "flex",
  alignItems: "center",
  textTransform: "none",
  color: "#2F3237",
  fontSize: "14px",
  "&:hover": { backgroundColor: "#239AFF" },
  "&:disabled": { backgroundColor: "#dddddd", color: "white" },
  background: "#239AFF",
  color: "white",
  fontSize: "10px",
  width: "144px",
  borderBottomRightRadius: "10px",
}));

export const RegenerateButton = styled(Button)(() => ({
  display: "flex",
  alignItems: "center",
  textTransform: "none",
  color: "#239AFF",
  fontSize: "18px",
  background: "#fffff",
  fontSize: "14px",
  width: "fit-content",
  borderColor:'#239AFF',
  borderBottomRightRadius: "10px",
})); 

export const BorderButton = styled(Button)(() => ({
  display: "flex",
  alignItems: "center",
  textTransform: "none",
  "&:hover": { backgroundColor: "#239AFF" },
  "&:disabled": { backgroundColor: "#dddddd", color: "white" },
  background: "#239AFF",
  color: "white",
  fontSize: "12px",
  fontWeight: "bold",
  width: "120px",
  borderRadius: "10px",
}));

export const SubmitButton = styled(Button)(() => ({
  display: "flex",
  alignItems: "center",
  textTransform: "none",
  "&:hover": { backgroundColor: "#239AFF" },
  "&:disabled": { backgroundColor: "#dddddd", color: "white" },
  background: "#1F76FE",
  color: "white",
  fontSize: "12px",
  fontWeight: "bold",
  width: "120px",
  borderRadius: "10px",
  width: "163px",
  marginTop:'30px'
}));
export const CloseloseButton = styled(Button)(() => ({
  display: "flex",
  alignItems: "center",
  textTransform: "none",
  "&:hover": { backgroundColor: "#b34d4d" },
  "&:disabled": { backgroundColor: "#dddddd", color: "white" },
  background: "#A52A2A",
  color: "white",
  fontSize: "12px",
  fontWeight: "bold",
  width: "120px",
  borderRadius: "10px",
  width: "163px",
}));


export const NoBorderButton = styled(Button)(() => ({
  display: "flex",
  alignItems: "center",
  textTransform: "none",
  fontSize: "14px",
  "&:hover": { backgroundColor: "white" },
  color: "1F76FE",
  width: "120px",
  display: "flex",
  alignItems: "center",
}));
