import React, { useEffect, useState } from "react";
import "./style.css";
import { menuItems } from "../../utilities/constants";
import MenuItem from "./MenuItem";
import { getMenuIcons } from "../../redux/slices/summary";
import { useDispatch, useSelector } from "react-redux";

const ServicesMenu = ({ setSelectedIndex, selectedIndex }) => {
  const { menuIcon } = useSelector((state) => state.summary);
  const dispatch = useDispatch();
  useEffect(() => {
    if (menuIcon === "") dispatch(getMenuIcons(menuItems[0].icon));
  }, []);

  return (
    <div className="services-menu-container">
      {menuItems.map((item, index) => {
        return (
          <MenuItem
            onClick={() => {
              setSelectedIndex(index);
            }}
            selected={selectedIndex === index}
            key={index}
            title={item.title}
            icon={item.icon}
          />
        );
      })}
    </div>
  );
};

export default ServicesMenu;
