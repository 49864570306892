import React, { useEffect, useState } from "react";
import {
  ServiceDescription,
  ServiceInput,
  ServicesShapes,
} from "../../../components";
import translation from "../../../assets/img/translation.svg";
import { useTranslation } from "react-i18next";
import { resetTranslation } from "../../../redux/slices/translation";
import { useDispatch, useSelector } from "react-redux";

import { fetchAItranslation } from "../../../redux/slices/translation";

const Translation = ({ index }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [leftInput, setLeftInput] = useState("");
  const [rightInput, setRightInput] = useState(""); 

  const { translatedText, statusTranslation, error } = useSelector(
    (state) => state.translation
  );

  useEffect(() => {
    return () => {
      dispatch(resetTranslation());
      setRightInput("");
    };
  }, [index]);

  return (
    <div
      div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
      }}
    >
      <ServiceInput
        leftInput={leftInput}
        setLeftInput={setLeftInput}
        rightInput={rightInput}
        setRightInput={setRightInput}
        placeholderTitle={"Translation"}
        translation={true}
        title={t("Translator")}
        check={fetchAItranslation}
        textResult={translatedText}
        status={statusTranslation}
      />
      <ServicesShapes />

      <div className="rounded-gray-shape"></div>
      <ServiceDescription
        descriptionImage={translation}
        descriptionTitle={t("Translation")}
        description={t(
          "Our translation service, enhanced by advanced AI technology, rapidly analyzes your text, extracts crucial information, and produces accurate translations, saving you valuable time and effort."
        )}
      />
    </div>
  );
};
export default Translation;
