import React, { useEffect } from "react";
import "./style.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchAd } from "../../redux/slices/summary";
import SquarePub from "../Pub/SquarePub";
const TopTitle = ({ summary }) => {


  const { t } = useTranslation();
  return (
    <div className="titel-container">
      <div>
      </div>
      <div className="titel">{t("Write smarter, faster and correctly")}</div>
      <div>
      </div>
    </div>
  );
};

export default TopTitle;
