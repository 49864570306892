import React from "react";
import "./style.css";
import VerticalPub from "../Pub/VerticalPub";
import RoundedShapIcon from "../RoundedShapIcon";
import BoxOpen from "../../assets/img/BoxOpen.svg";
import { menuItems } from "../../utilities/constants";
import ParaPharase from "../../assets/img/ParaPharase.svg";
import spellingRounded from "../../assets/img/spellingRounded.svg";
import summaryRounded from "../../assets/img/summaryRounded.svg";
import translationRounded from "../../assets/img/translationRounded.svg";
import { useTranslation } from "react-i18next";
import verticalPub from '../../assets/img/verticalPub.svg'
import horizentalPub from '../../assets/img/horizentalPub.svg'


const ServicesShapes = () => {
  const { t }=useTranslation()
  return (
    <div className="services-container">
    {/* <VerticalPub pub={verticalPub} /> */}
    <div className="services-content">
      <div className="content-titel">
        <div className="bold-titel">{t("Our Services")}</div>
        <div className="titel-discription">
          {t("Experience our AI-powered services and unlock new possibilities")}
        </div>
      </div>
      <div className="services-content-shaps">
        <div className="first-line">
          <RoundedShapIcon
            color={"#3BA6F4"}
            icon={summaryRounded}
            title={t("Summary")}
          />
          <RoundedShapIcon
            color={"#6DCFF6"}
            icon={ParaPharase}
            title={t("Paraphrase")}
          />
        </div>
        <div className="second-line">
          <RoundedShapIcon
            color={"#FF6CCD"}
            icon={translationRounded}
            title={t("Translation")}
          />
          <div className="box-open-container">
            <img width={'100%'} src={BoxOpen} alt="BoxOpen" />
          </div>
          <RoundedShapIcon
            color={"#4267B2"}
            icon={spellingRounded}
            title={t("Spelling")}
          />
        </div>
      </div>
    </div>
    {/* <VerticalPub pub={verticalPub} /> */}
  </div>
  );
};

export default ServicesShapes;
