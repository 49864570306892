import React, { useEffect } from "react";
import "./style.css";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getMenuIcons } from "../../redux/slices/summary";

const MenuItem = ({ icon, title, selected, onClick }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <div
      onClick={() => {
        dispatch(getMenuIcons(icon));
        onClick();
      }}
      className={
        selected ? "services-menu-item-selected" : "services-menu-item"
      }
    >
      <img
        className="services-menu-item-icon"
        style={{ margin: "5px" }}
        src={icon}
        width={20}
        height={20}
      />
      <span className="services-menu-item-title">{t(title)}</span>
    </div>
  );
};

export default MenuItem;
