import React from "react";
import "./style.css";
import shop from "../../assets/img/shop.svg";

const SquarePub = ({ src, href, description, promo }) => {
  return (
    <a className="pub-container" href={href} target="_blank">
      {description && <p className="description-pub">{description}</p>}
      {promo && promo !== "0%" && (
        <div className="pub-promo">
          -{promo} <br /> PROMO
        </div>
      )}
      <img src={src} width={200} height={200} />
      <div className="shop-button">
        <div className="shop-icon">
          <img src={shop} height={24} width={24} />
        </div>
        <div className="shop-text">SHOP NOW</div>
      </div>
    </a>
  );
};

export default SquarePub;