import React from "react";
import "./style.css";
import facebookIcon from "../../assets/img/facebook.svg";
import twiterIcon from "../../assets/img/twiter.svg";
import LinkdinIcon from "../../assets/img/linkdin.svg";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="footer-container">
      <div className="footer-social">
        <a href="https://www.facebook.com/people/Smart-Writing-AI/61550106362937/">
          <img src={facebookIcon} width={20} height={20} />
        </a>
        <a href="https://twitter.com/AiSmartWriting?fbclid=IwAR0wgh27rtdySYAK_glO2b35111lEPbLHYLTXeTIPeUtbgfSk7Lq5LwEZNI">
          <img src={twiterIcon} width={20} height={20} />
        </a>
        <a href="https://www.linkedin.com/company/smartwriting-ai/?fbclid=IwAR14hTFDYS402-mWmm6qAvNJulQ-wZGytQ8hiD1cyA6oLuCK5tZYk49mneo">
          <img src={LinkdinIcon} width={20} height={20} />
        </a>
      </div>
      <div className="footer-service">
        <a href="/">{t("Services")}</a>
        <a href="Contact">{t("Contact")}</a>
      </div>
      <div className="footer-policy-container">
        <div className="footer-policy">
          <div style={{marginRight:'5px'}}>@ Smart-Writing Pty Ltd 2023. </div>
          <div> All Rights Reserved.</div>
          {/* <div className="footer-policy-link">
          <a href="">Privacy Policy</a>
          <a href="">Terms of Service</a>
          <a href="">Contacts</a>
     </div> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
