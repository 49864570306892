import React, { useEffect, useState } from "react";
import {
  ServiceDescription,
  ServiceInput,
  ServicesShapes,
} from "../../../components";
import smmaryImage from "../../../assets/img/summaryImage.svg";
import { useTranslation } from "react-i18next";
import { fetchAISummary, resetSummary } from "../../../redux/slices/summary";
import { useDispatch, useSelector } from "react-redux";

const Summary = ({ index }) => {
  const [leftInput, setLeftInput] = useState(null);
  const [rightInput, setRightInput] = useState(null);
  const { t } = useTranslation();
  const dispatch= useDispatch()

  const { summaryText, statusSummary, error } = useSelector((state) => state.summary);
  useEffect(() => {
    return () => {
      dispatch(resetSummary());
      setRightInput("");
    };
  }, [index]);
  return (
    <div
      
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
      }}
    >
      <ServiceInput
        leftInput={leftInput}
        setLeftInput={setLeftInput}
        rightInput={rightInput}
        setRightInput={setRightInput}
        placeholderTitle={"Summary"}
        summary={true}
        summarizing={fetchAISummary}
        textResult={summaryText}
        status={statusSummary}
      />
      <ServicesShapes />
      <div className="rounded-gray-shape"></div>
      <ServiceDescription
        descriptionImage={smmaryImage}
        descriptionTitle={t("Summary Ai Toll")}
        description={t(
          "Our summary service, powered by advanced AI technology, swiftly analyzes your text, extracting essential information, and generates a condensed summary, saving you valuable time and effort."
        )}
      />
      

    </div>
  );
};
export default Summary;
