import React, { useEffect, useState } from "react";
import {
  ServiceDescription,
  ServiceInput,
  ServicesShapes,
} from "../../../components";
import paraphrasing from "../../../assets/img/paraphrasing.svg";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchAIPraphrase, resetParaphrase } from "../../../redux/slices/paraphrese";
const Paraphrase = () => {
  const { paraphraseText, statusParaphrase, error } = useSelector((state) => state.paraphrase);

  const { t } = useTranslation();
  const [leftInput, setLeftInput] = useState("");
  const [rightInput, setRightInput] = useState("");
  const dispatch = useDispatch()
  useEffect(() => {
    return () => {
      dispatch(resetParaphrase())
      setRightInput("");
    };
  }, []);

  return (
    <div
      div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
      }}
    >
      <ServiceInput
        leftInput={leftInput}
        setLeftInput={setLeftInput}
        rightInput={rightInput}
        setRightInput={setRightInput}
        placeholderTitle={"paraphrase"}
        paraphrase={true}
        title={t("Paraphrase")}
        check={fetchAIPraphrase}
        textResult={paraphraseText}
        status={statusParaphrase} 
      />
      <ServicesShapes />

      <div className="rounded-gray-shape"></div>
      <ServiceDescription
        descriptionImage={paraphrasing}
        descriptionTitle={t("Paraphrasing Tool Features")}
        description={t(
          "Experience our AI-powered paraphrasing service, which efficiently examines your text, captures vital details, and delivers rephrased content, ensuring you save precious time and energy."
        )}
      />
    </div>
  );
};
export default Paraphrase;
