import { Slide } from "@mui/material";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { enqueueSnackbar } from "./notifier";

const initialState = {
  translatedText: "",
  statusTranslation: "idle",
  error: null,
};


const api =process.env.REACT_APP_API_URL
//   return response.data.

export const fetchAItranslation = createAsyncThunk(
  "translation/fetchAItranslation ",
  async ({ firstLanguage, secondLanguage, text },thunkAPI) => {
    let data;
    try {
      const response = await axios.post(`${api}RapidApi/translation`,{firstLanguage,secondLanguage,text})
      data = await response.data.translated_text[secondLanguage];
      if (data) {
        return data
      }
      throw new Error(response.statusText);
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message:
            "Request failed",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            TransitionComponent: Slide,
          },
        })
      );

      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

const slice = createSlice({
  name: "translation ",
  initialState,
  reducers: { reset: () => initialState },
  extraReducers: {
    [fetchAItranslation.pending]: (state, action) => {
      state.statusTranslation = "loading";
    },
    [fetchAItranslation.fulfilled]: (state, action) => {
      state.statusTranslation = "succeeded";
      state.translatedText = action.payload;
    },
    [fetchAItranslation.rejected]: (state, action) => {
      state.statusTranslation = "failed";
      state.error = action.payload;
    },
  },
});

export const reducer = slice.reducer;

export const resetTranslation = () => (dispatch) => {
    dispatch(slice.actions.reset());
  };
export default slice;
