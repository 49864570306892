import React from 'react'
import './style.css'

const RoundedShapIcon = ({icon, color, title}) => {
  return (
    <div className='rouned-shap' style={{background:color}}>
        <img className='icon-shape' src={icon} />
        <div className='title-Shape'>{title}</div>
    </div>
  )
}

export default RoundedShapIcon