import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { MainLayout } from './layouts'
import Services from './pages/services'
import ContactPage from './pages/contact'
import Conditions from './pages/conditions'

export const  Routes = () => {
  return (
    <Router>
      <Switch>
        <MainLayout>
        <Route exact path={`/`} component={Services} />
        <Route exact path={`/Contact`}  component={ContactPage} />
        <Route exact path={`/Conditions`}  component={Conditions} />

        </MainLayout>
      </Switch>
    </Router>
  )
}
export default Routes