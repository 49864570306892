import "./App.css";
import { SnackbarProvider } from "notistack";
import { MainLayout, Notifier } from "./layouts";
import Routes from "./routes";

function App() {
  return (
    <div className="App-container">
      <SnackbarProvider
        maxSnack={12}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Notifier />
        <Routes/>
    </SnackbarProvider>
    </div>
  );
}

export default App;
