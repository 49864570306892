import React, { useState } from "react";
import "./style.css";
import { Button, Menu, MenuItem } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
const LanguageSelector = ({languages, language, setLanguage }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className="language-selector-container">
      <span>
        <Button
          sx={{
            textTransform: "none",
            color: "#2F3237",
            fontSize: "14px",
            "&:hover": { backgroundColor: "none" },
          }}
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <img className="flags-icon" src={language?.icon} />
          {language?.label}
          {!open ? (
            <KeyboardArrowDownIcon fontSize="small" />
          ) : (
            <KeyboardArrowUpIcon fontSize="small" />
          )}
        </Button>

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {languages?.map((item, index) => {
            return (
              <MenuItem

                key={index}
                onClick={() => {
                  setLanguage(item);
                  handleClose();
                }}
              >
                <img width={20} height={20} src={item.icon} style={{marginRight:'5px'}}/><span>{item.label}</span>
              </MenuItem>
            );
          })}
        </Menu>
      </span>
    </div>
  );
};

export default LanguageSelector;
