import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import smartIcon from "../../assets/img/smartWritingicon.png";
import { styled } from "@mui/material";
import {
  BorderButton,
  CloseloseButton,
  SubmitButton,
} from "../StyledComponenets";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../LanguageSelector";
import {
  SummaryArray,
  TransaltionArray,
  localesArray,
} from "../../utilities/constants";

const LanguageDetectModal = ({
  onChek,
  open,
  handleClose,
  message,
  error,
  setFirstLanguage,
  islength,
  firstLanguage,
  paraphrase,
  summary,
}) => {
  const { t } = useTranslation();
  const StyledTextField = styled(TextField)(() => ({
    width: "100%",
    background: "#F4F4F4",

    "& .MuiInput-underline:before": {
      borderBottom: "none", // Remove the underline when not focused
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none", // Remove the underline when focused
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none", // Remove the underline on hover
    },
  }));

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-evenly",
            padding: "50px",
            width: "480px",
            height: error ? "150px" : "250px",
          }}
        >
                    <div
            style={{
              fontWeight: "bold",
              fontSize: !islength ? "22px" : "18px",
              color: !islength ? "red" : "green",
              textAlign: "center",
              //marginBottom:'10px'
            }}
          >
            {islength ? t('Selectionner la langue du text') : t(message)}
          </div>
          {islength && (
            <LanguageSelector
              languages={
                summary
                  ? SummaryArray
                  : paraphrase
                  ? localesArray
                  : TransaltionArray
              }
              language={firstLanguage}
              setLanguage={setFirstLanguage}
            />
          )}
          {
            !error || islength ? (
              <SubmitButton
                onClick={() => {
                  onChek();
                  handleClose();
                }}
              >
                {" "}
                {t("Submit")}
              </SubmitButton>
            ) : (
              <CloseloseButton
                onClick={() => {
                  handleClose();
                }}
              >
                {" "}
                {t("Close")}
              </CloseloseButton>
            ) // TODO button fermer
          }
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default LanguageDetectModal;
