import React, { useState } from "react";
import "./style.css";
import smartIcon from "../../assets/img/smartWritingicon.png";
import LanguageRoundedIcon from "@mui/icons-material/LanguageRounded";
import { Button, Menu, MenuItem } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { AppLanguages } from "../../utilities/constants";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

const Header = () => {
  const { t } = useTranslation()
  const lang =JSON.parse(localStorage.getItem('lang'))
  const [anchorEl, setAnchorEl] = useState(null);
  const [language, setLanguage] = useState(lang ? lang : AppLanguages[0] );

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChangeLanguage = (lang) => {
    setLanguage(lang);
    localStorage.setItem('lang',JSON.stringify(lang))
    i18next.changeLanguage(lang.code)
  };

  return (
    <header className="header-container">
      <div className="logo">
        <a href="/">
        <img src={smartIcon} width={106} height={83} />
        </a>
      </div>
      <div className="navigation">
        <a href="/">{t("Services")}</a>
        <a href="Contact">{t("Contact")}</a>

      </div>
      <div className="language">
      <a href="Conditions">{t("Terms & Conditions")}</a>
       <span style={{display:'flex', alignItems:'center'}}>
        <LanguageRoundedIcon className="language-icon" />
          <Button
            sx={{
              textTransform: "none",
              color: "#3E4958",
              fontSize: "16px",
              fontWeight:'bold',
              
              "&:hover": { backgroundColor: "none" },
            }}
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <img width={20} height={20} src={language.icon} style={{marginRight:'5px'}}/> {language?.label}
            {!open ? (
              <KeyboardArrowDownIcon fontSize="small" />
            ) : (
              <KeyboardArrowUpIcon fontSize="small" />
            )}
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {AppLanguages.map((item, index) => {
              return (
                <MenuItem
              
                  key={index}
                  onClick={() => {
                    handleChangeLanguage(item);
                    // setLanguage(item);
                    handleClose();
                  }}
                >
                <img width={20} height={20} src={item.icon} style={{marginRight:'5px'}}/>  <span>{item.label}</span>
                </MenuItem>
              );
            })}
          </Menu>
        </span>
      </div>
    </header>
  );
};

export default Header;
