import React, { useState } from "react";
import { Button, Modal, Box } from "@mui/material";
import adBlock from "../../assets/img/adBlock.svg";
function AdBlockModal() {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    window.location.reload();
  };

  return (
    <Modal
      open={open}
      aria-labelledby="adblock-modal-title"
      aria-describedby="adblock-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius:'20px',
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          p: 4,
        }}
      >
        <h2 id="adblock-modal-title">AdBlock Detected</h2>
        <img src={adBlock} width={"100%"} height={100}></img>
        <p id="adblock-modal-description">
          Please disable your AdBlock to access this content.
        </p>
        <Button
          style={{ background: "#fa3123" }}
          onClick={handleClose}
          variant="contained"
          color="primary"
        >
          I've disabled my AdBlock!
        </Button>
      </Box>
    </Modal>
  );
}

export default AdBlockModal;
