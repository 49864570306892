import React, { useEffect, useState } from "react";
import "./style.css";
import Header from "../Header";
import Footer from "../Footer";
import { AdBlockModal, SocialMediaComponent } from "../../components";
import ProgressBox from "../../components/loader";
import { useDispatch, useSelector } from "react-redux";
import { useDetectAdBlock } from "adblock-detect-react";
import SquarePub from "../../components/Pub/SquarePub";
import { fetchAd, fetchAdBlockerStatus } from "../../redux/slices/summary";

const MainLayout = ({ children }) => {
  const { statusTranslation } = useSelector((state) => state.translation);
  const { statusParaphrase } = useSelector((state) => state.paraphrase);
  const { statusSummary, Ad, statusAds, AdBlockerStatus } = useSelector(
    (state) => state.summary
  );
  const { statusSpelling } = useSelector((state) => state.spellingCorrection);
  const adBlock = useDetectAdBlock();
  const [adBlockDetected, setAdBlockDetected] = useState(adBlock);
  const isContactPage = window.location.pathname === "/Contact";
  const isCondition = window.location.pathname === "/Conditions";

  const dispatch = useDispatch();

  useEffect(() => {
    setAdBlockDetected(adBlock);
  }, [adBlock]);

  const handleCloseModal = () => {
    setAdBlockDetected(false);
  };
  const load =
    statusSummary === "loading" ||
    statusTranslation === "loading" ||
    statusParaphrase === "loading" ||
    statusSpelling === "loading";
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentAds, setCurrentAds] = useState([]);

  useEffect(() => {
    dispatch(fetchAdBlockerStatus());
  }, []);

  useEffect(() => {
    dispatch(fetchAd());

    const interval = setInterval(() => {
      setCurrentIndex((currentIndex + (isContactPage ? 2 : 4)) % Ad.length);
    }, process.env.REACT_APP_PUB_INTERVAL);

    return () => clearInterval(interval);
  }, [Ad?.length, currentIndex]);
  useEffect(() => {
    let AdsList =
      Ad.length > 0 &&
      Ad?.slice(currentIndex, currentIndex + (isContactPage ? 2 : 4));
    if (statusAds === "succeeded") {
      if (AdsList.length < 4 && !isContactPage) {
        const difference = 4 - AdsList.length;
        const randomAds = [];

        while (randomAds.length < difference) {
          const randomIndex = Math.floor(Math.random() * Ad.length);
          const randomAd = Ad[randomIndex];
          if (!randomAds.includes(randomAd) && !AdsList.includes(randomAd)) {
            randomAds.push(randomAd);
          }
        }

        AdsList = AdsList.concat(randomAds);
      } else if (AdsList.length < 2 && isContactPage) {
        const difference = 2 - AdsList.length;
        const randomAds = [];

        while (randomAds.length < difference) {
          const randomIndex = Math.floor(Math.random() * Ad.length);
          const randomAd = Ad[randomIndex];
          if (!randomAds.includes(randomAd)) {
            randomAds.push(randomAd);
          }
        }

        AdsList = AdsList.concat(randomAds);
      }

      setCurrentAds(AdsList);
    }
  }, [statusAds]);

  return (
    <div className="layout-container">
      <>
        {adBlockDetected && AdBlockerStatus && (
          <AdBlockModal open={adBlockDetected} onClose={handleCloseModal} />
        )}
        {load && (
          <div class="container-loader">
            <ProgressBox />
            <div class="overlay"></div>
          </div>
        )}
        <Header />
        {!isCondition && <div className="blue-shape" />}
        <SocialMediaComponent />
        <main className="main-layout">
          {!isCondition && Object.keys({ Ad }).length > 0 && (
            <div>
              <div
                className="ad-left"
                style={{ top: isContactPage ? "45%" : "100%" }}
              >
                {currentAds.length > 0 &&
                  currentAds?.map((ad, index) => {
                    return (
                      <SquarePub
                        key={ad._id}
                        promo={ad.promo}
                        description={ad.description}
                        href={ad.productLink}
                        src={ad.imageLink}
                      />
                    );
                  })}
              </div>
              <div
                className="ad-right"
                style={{ top: isContactPage ? "45%" : "100%" }}
              >
                {currentAds.length > 0 &&
                  currentAds
                    ?.slice()
                    .reverse()
                    .map((ad, index) => {
                      return (
                        <SquarePub
                          key={ad.index}
                          promo={ad.promo}
                          description={ad.description}
                          href={ad.productLink}
                          src={ad.imageLink}
                        />
                      );
                    })}
              </div>
            </div>
          )}
          {children}
        </main>
        <Footer />
      </>
    </div>
  );
};

export default MainLayout;
