import React from "react";
import "./style.css";
import twiterIcon from "../../assets/img/twiterIcon.svg";
import linkedinIcon from "../../assets/img/linkedinIcon.svg";
import fbIcon from "../../assets/img/fbIcon.svg";

const SocialMediaComponent = () => {
  return (
    <div className="social-media-container">
   
      <a href="https://www.linkedin.com/company/smartwriting-ai/?fbclid=IwAR14hTFDYS402-mWmm6qAvNJulQ-wZGytQ8hiD1cyA6oLuCK5tZYk49mneo" target="_blank">
        <img src={linkedinIcon} />
      </a>
      <a href="https://www.facebook.com/people/Smart-Writing-AI/61550106362937/" target="_blank">
        <img src={fbIcon} />
      </a>
    </div>
  );
};

export default SocialMediaComponent;
