import React from 'react';
import { Box, Typography } from '@mui/material';
import './style.css'; // Import the CSS file for the animation

const ProgressBox = (props) => {
  return (
    <div>
      <Box sx={{zIndex:'999',left:'45%', top:'45%'}} position="fixed" display="inline-flex">
        <div className="spinner-container">
          <div className="spinner">
            <div className="circle"></div>
          </div>
        </div>
        <Box
          top={85}
          left={60}
          bottom={0}
          right={0}
          position="relative"
          display="flex"
          alignItems="center"
          justifyContent="center"
         
        >
          <Typography
            sx={{
              zIndex: 0,
              color: '#0551AC',
              fontSize: '20px',
              fontWeight: 'bold',
            }}
          >
            Loading...
          </Typography>
        </Box>
      </Box>
    </div>
  );
}

export default ProgressBox;