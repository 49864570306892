import React from "react";
import "./style.css";
import HorizentalPub from "../Pub/HorizentalPub";
import VerticalPub from "../Pub/VerticalPub";


import { useTranslation } from "react-i18next";
import verticalPub from '../../assets/img/verticalPub.svg'
import horizentalPub from '../../assets/img/horizentalPub.svg'

const ServiceDescription = ({
  descriptionImage,
  descriptionTitle,
  description,
}) => {

  const {t } = useTranslation()
  return (
    <div className="service-description-container">
    <div className="service-description-content">
      {/* <VerticalPub pub={verticalPub} /> */}
      <div className="description-container">
        
        <div className="description-content">
          <div className="integrations">{t("Integrations")}</div>
          <div className="service-description-title">{descriptionTitle}</div>
          <div className="service-description-paragraph">{description}</div>
        </div>
        <div className="description-image">
          <img src={descriptionImage} alt="Description" />
        </div>
      </div>
      {/* <VerticalPub pub={verticalPub} /> */}
    </div>
    <div className="down-pub">
      {/* <HorizentalPub pub={horizentalPub} /> */}
    </div>
  </div>
  );
};

export default ServiceDescription;
