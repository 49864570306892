import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Configuration, OpenAIApi } from "openai";
import { fixGrammarErrors } from "../../utilities/utilitiesFun";
import { enqueueSnackbar } from "./notifier";
import { Slide } from "@mui/material";

const initialState = {
  spellingCorrectionReg: "",
  spellingCorrection: [],
  statusSpelling: "idle",
  error: null,
  data:'',
  text:''
};

const api = process.env.REACT_APP_API_URL;

export const fetchAISpellingCorrection = createAsyncThunk(
  "spellingCorrection/fetchAISpellingCorrection ",
  async ({ text, firstLanguage }, thunkAPI) => {
    let data;
    try {
      const response = await axios.post(`${api}RapidApi/SpellingCorrection`, {
        text,
        firstLanguage,
      });
      data = await response.data;
      if (data) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      thunkAPI.dispatch(
        enqueueSnackbar({
          message: "Request failed",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            TransitionComponent: Slide,
          },
        })
      );

      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

const slice = createSlice({
  name: "spellingCorrection ",
  initialState,
  reducers: {
    reset: () => initialState,
    regenerate: (state,action) =>
     { state.spellingCorrection = fixGrammarErrors(
        state.text,
        state.data,
        action.payload
      );},
  },
  extraReducers: {
    [fetchAISpellingCorrection.pending]: (state, action) => {
      state.statusSpelling = "loading";
    },
    [fetchAISpellingCorrection.fulfilled]: (state, action) => {
      state.statusSpelling = "succeeded";
      state.data = action.payload; //how to get params
      state.spellingCorrection = fixGrammarErrors(
        action.meta.arg.text,
        action.payload,0
      );
      state.text=action.meta.arg.text
    },
    [fetchAISpellingCorrection.rejected]: (state, action) => {
      state.statusSpelling = "failed";
      state.error = action.payload;
    },
  },
});

export const reducer = slice.reducer;

export const resetSpellingCorrecton = () => (dispatch) => {
  dispatch(slice.actions.reset());
};
export const regenarateSpellingCorrecton = (index) => (dispatch) => {
  dispatch(slice.actions.regenerate(index));
};
export default slice;
