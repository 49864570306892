import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import smartIcon from "../../assets/img/smartWritingicon.png";
import { styled } from "@mui/material";
import { BorderButton, SubmitButton } from "../StyledComponenets";
import { useTranslation } from "react-i18next";

const SumuraizeModal = ({ onSummary,setOpen, open, handleClose,setSentencNb,sentencNb}) => {
  const {t} =useTranslation()
  const StyledTextField = styled(TextField)(() => ({
    width: "100%",
    background: "#F4F4F4",

    "& .MuiInput-underline:before": {
      borderBottom: "none", // Remove the underline when not focused
    },
    "& .MuiInput-underline:after": {
      borderBottom: "none", // Remove the underline when focused
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "none", // Remove the underline on hover
    },
  }));

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-evenly",
            padding: "50px",
            width: "480px",
            height: "340px",
          }}
        >
          <img src={smartIcon} width={80} height={63} />
          <div
            style={{
              fontWeight: "bold",
              fontSize: "22px",
              textAlign: "center",
            }}
          >
{           t ('How many sentences you want in summary')
}          </div>
          <StyledTextField
          value={sentencNb}
           autoFocus
            margin="dense"
            id="name"
            type="email"
            placeholder={t("Number of sentences")}
            fullWidth
            onChange={(e)=>setSentencNb(e.target.value)}
          />
          <SubmitButton onClick={()=>{onSummary()
        handleClose() 
       }}> {t('Submit')}</SubmitButton>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default SumuraizeModal;
