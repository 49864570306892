import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { enqueueSnackbar } from "./notifier";
import { Slide } from "@mui/material";
import { concatenateElementsAtIndex } from "../../utilities/utilitiesFun";

const initialState = {
  paraphraseText: "",
  statusParaphrase: "idle",
  error: null,
  nb:0,
  data:[]
};

const api = process.env.REACT_APP_API_URL;
//   return response.data.

export const fetchAIPraphrase = createAsyncThunk(
  "translation/fetchAIPraphrase ",
  async ({  text },thunkAPI) => {
    let data;
    try {
      const response = await axios.post(`${api}RapidApi/paraphrase`,  {
        text,
        type: "multiple",
      });
      data = await response.data;
      if (data) {
        
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
     thunkAPI.dispatch(
        enqueueSnackbar({
          message:
            "Request failed",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            TransitionComponent: Slide,
          },
        })
      );

      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

const slice = createSlice({
  name: "paraphrase ",
  initialState,
  reducers: { reset: () => initialState,
    regenerate: (state,action) =>
    { state.paraphraseText = concatenateElementsAtIndex(
       state.data,action.payload
     );}, },
  extraReducers: {
    [fetchAIPraphrase.pending]: (state, action) => {
      state.statusParaphrase = "loading";
    },
    [fetchAIPraphrase.fulfilled]: (state, action) => {
      let test =''
      state.statusParaphrase = "succeeded";
      state.data = action.payload
      state.nb=action.payload[0].length
      state.paraphraseText = concatenateElementsAtIndex(action.payload,0);
    },
    [fetchAIPraphrase.rejected]: (state, action) => {
      state.statusParaphrase = "failed";
      state.error = action.payload;
    },
  },
});

export const reducer = slice.reducer;

export const resetParaphrase = () => (dispatch) => {
  dispatch(slice.actions.reset());
};
export const regenaratePara = (index) => (dispatch) => {
  dispatch(slice.actions.regenerate(index));
};
export default slice;
