import React, { useState } from "react";
import {  ServicesMenu, TopTitle } from "../../components";
import "./style.css";
import Summary from "./componenets/summary";
import Translation from "./componenets/translation";
import SpellingCorrection from "./componenets/spellingCorrection";
import Paraphrase from "./componenets/paraphrase";
import { menuItems } from "../../utilities/constants";
const Services = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <div className="service-container">
  
      <TopTitle summary={selectedIndex == 0 ? true : false} />
      <div className="service-menu">
        <ServicesMenu
          selectedIndex={selectedIndex}
          setSelectedIndex={setSelectedIndex}
        />
      </div>
      <div className="service-content">
        {selectedIndex == 0 && <Summary  index={selectedIndex} summary={true} />}
        {selectedIndex == 1 && <Translation  index={selectedIndex} translation={true} />}
        {selectedIndex == 2 && <SpellingCorrection spelling={true} index={selectedIndex} />}
        {selectedIndex == 3 && <Paraphrase   index={selectedIndex} />}
      </div>
    </div>
  );
};

export default Services;
