import { combineReducers } from '@reduxjs/toolkit';
import { reducer as notifierReducer } from '../slices/notifier';
import { reducer as translationReducer } from '../slices/translation';
import { reducer as spellingCorrectionReducer } from '../slices/spellingCorrection';
import { reducer as summaryReducer } from '../slices/summary';
import { reducer as paraphraseReducer } from '../slices/paraphrese';



const combinedReducer = combineReducers({
    notifier: notifierReducer,
    translation:translationReducer,
    spellingCorrection:spellingCorrectionReducer,
    summary:summaryReducer,
    paraphrase:paraphraseReducer


});

const rootReducer = (state, action) => {

  return combinedReducer(state, action);
};

export default rootReducer;
