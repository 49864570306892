import React, { useEffect, useState } from "react";
import "./style.css";
import LanguageSelector from "../LanguageSelector";
import {
  SummaryArray,
  TransaltionArray,
  languages,
  localesArray,
} from "../../utilities/constants";
import { IconButton, Slide } from "@mui/material";
import MaterialSymbolsDeleteOutlineRounded from "../../assets/img/delete";
import DoubleInput from "./DoubleInput";
import RightSwitch from "../../assets/img/RightSwitch.svg";
import LeftSwitch from "../../assets/img/LeftSwitch.svg";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { enqueueSnackbar } from "../../redux/slices/notifier";
import { franc, francAll } from "franc";
import { regenarateSpellingCorrecton } from "../../redux/slices/spellingCorrection";
import { regenaratePara } from "../../redux/slices/paraphrese";
import { findLanguageByCode } from "../../utilities/utilitiesFun";
import LanguageDetectModal from "../LanguageDetectionModal";
import spin from "../../assets/img/spin.svg";
import { RegenerateButton } from "../StyledComponenets";
//import css module

const ServiceInput = ({
  title,
  placeholderTitle,
  handleChange,
  leftInput,
  setLeftInput,
  rightInput,
  setRightInput,
  check,
  textResult,
  status,
  summarizing,
  spelling,
  translation,
  paraphrase,
  summary,
}) => {
  const { t } = useTranslation();
  const [firstLanguage, setFirstLanguage] = useState(
    paraphrase ? localesArray[0] : languages[0]
  );
  const { nb } = useSelector((state) => state.paraphrase);

  const [secondLanguage, setSecondLanguage] = useState(languages[0]);
  const [switchIcon, setSwitchIcon] = useState(false);
  const [sentencNb, setSentencNb] = useState("");
  const [detectedLanguage, setDetectedLanguage] = useState(null);
  const [count, setCount] = useState(1);
  const [openLang, setOpenLang] = useState(false);
  const [langModal, setlangModal] = useState({});
  const [islength, setIsLength] = useState(false);

  const dispatch = useDispatch();
  const regenarate = () => {
    if (count < nb - 1) {
      dispatch(regenaratePara(count));
      setCount(count + 1);
    } else {
      dispatch(regenaratePara(count));
      setCount(0);
    }
  };

  useEffect(() => {
    if (status === "succeeded") {
      setRightInput(textResult);
    }
    if (status === "idle") {
      setRightInput("");
    }
  }, [status, textResult]);

  const handleDelete = () => {
    setLeftInput("");
  };

  const handleSwitch = () => {
    setSwitchIcon(!switchIcon);
    setFirstLanguage(secondLanguage);
    setSecondLanguage(firstLanguage);
    setLeftInput(rightInput);
    setRightInput(leftInput);
  };

  const handleChangLeftInput = (e) => {
    setLeftInput(e.target.value);
    const language = franc(e.target.value);
    setDetectedLanguage(language);
  };

  const handleCheck = () => {
    let result = {};
    if (leftInput.length >= process.env.REACT_APP_MAX_CHAR) {
      dispatch(
        enqueueSnackbar({
          message: t(
            "Maximum character limit exceeded. Please ensure your input does not exceed 5000 characters."
          ),
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            TransitionComponent: Slide,
          },
        })
      );
    } else {
      let result = {};
      const language = franc(leftInput);
      setDetectedLanguage(language);
      result = findLanguageByCode(
        paraphrase ? localesArray : TransaltionArray,
        detectedLanguage
      );
      setIsLength(handleWordLength());

      if (result.language && !handleWordLength()) {
        setFirstLanguage(result?.language);
        onCheck(result?.language);
      } else if (handleWordLength(leftInput)) {
        setOpenLang(true);
        setlangModal(result);
      } else {
        setOpenLang(true);
        setlangModal(result);
      }
    }
    setCount(1);
  };

  const handleWordLength = () => {
    if (leftInput?.trim().split(/\s+/).length <= 4) {
      return true;
    } else {
      return false;
    }
  };

  const onCheck = (lang) => {
    !summary
      ? dispatch(
          check({
            firstLanguage: lang.code,
            secondLanguage: secondLanguage.code,
            text: leftInput,
          })
        )
      : dispatch(
          summarizing({
            firstLanguage: lang.label,
            sentencNb: sentencNb,
            text: leftInput,
          })
        );
  };

  const handleClose = () => {
    setOpenLang(false);
  };
  const handleSummary = () => {
    if (leftInput.length >= process.env.REACT_APP_MAX_CHAR) {
      dispatch(
        enqueueSnackbar({
          message:
            "Maximum character limit exceeded. Please ensure your input does not exceed 5000 characters.",
          options: {
            key: new Date().getTime() + Math.random(),
            variant: "error",
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            TransitionComponent: Slide,
          },
        })
      );
    } else {
      let result = {};
      const language = franc(leftInput);
      setDetectedLanguage(language);
      result = findLanguageByCode(SummaryArray, detectedLanguage);
      setIsLength(handleWordLength(leftInput));
      if (result.language && !handleWordLength()) {
        setFirstLanguage(result?.language);
        onCheck(result?.language);
      } else if (handleWordLength(leftInput)) {
        setOpenLang(true);
        setlangModal(result);
      } else {
        setOpenLang(true);
        setlangModal(result);
      }
    }
  };

  return (
    <div className="service-input-container">
      {summary && (
        <div className="input-header">
          <div className="mid-input-header">
            <LanguageSelector
              languages={SummaryArray}
              language={firstLanguage}
              setLanguage={setFirstLanguage}
            />
            <IconButton
              onClick={handleDelete}
              sx={{
                textTransform: "none",
                color: "#2F3237",
                fontSize: "14px",
                "&:hover": { backgroundColor: "#F1F3F9" },
              }}
            >
              <MaterialSymbolsDeleteOutlineRounded fontSize="small" />
              {t("Delete")}
            </IconButton>
          </div>
          <div className="mid-input-header">
            <div
              style={{
                color: "#2F3237",
                fontSize: "14px",
                fontWeight: "bold",
              }}
            >
              {t("Result")}
            </div>
          </div>
        </div>
      )}
      {translation && (
        <div className="input-header">
          <div className="mid-input-header">
            <div className="input-title">{title}</div>
            <LanguageSelector
              languages={TransaltionArray}
              language={firstLanguage}
              setLanguage={setFirstLanguage}
            />
          </div>
          <div className="switch-icon">
            <img
              className="switch-button"
              src={switchIcon ? RightSwitch : LeftSwitch}
              onClick={() => handleSwitch()}
            />
          </div>
          <div className="mid-input-header">
            <div
              style={{
                color: "#2F3237",
                fontSize: "14px",
                fontWeight: "bold",
              }}
            >
              <LanguageSelector
                languages={TransaltionArray}
                language={secondLanguage}
                setLanguage={setSecondLanguage}
              />
            </div>
          </div>
        </div>
      )}
      {paraphrase && (
        <div className="input-header">
          <div className="mid-input-header">
            <div className="input-title">{title}</div>
            <LanguageSelector
              languages={localesArray}
              language={firstLanguage}
              setLanguage={setFirstLanguage}
            />
          </div>
          <div className="mid-input-header">
            <div
              style={{
                color: "#2F3237",
                fontSize: "14px",
                fontWeight: "bold",
              }}
            >
              {t("Result")}
            </div>
            {rightInput && paraphrase && !spelling && (
              <RegenerateButton
                onClick={regenarate}
                disabled={leftInput == null || leftInput == ""}
              >
                {" "}
                <img
                  style={{ marginRight: "10px" }}
                  src={`${spin}`}
                  width={20}
                  height={20}
                />
                <span>{t("try another reformulation")}</span>
              </RegenerateButton>
            )}
          </div>
        </div>
      )}
      <div className="input-containt">
        <DoubleInput
          handleChangLeftInput={handleChangLeftInput}
          handleTranslation={handleChange}
          placeholderTitle={placeholderTitle}
          summary={summary}
          leftInput={leftInput}
          rightInput={rightInput}
          onCheck={handleCheck}
          onSummary={handleSummary}
          setLeftInput={setLeftInput}
          setSentencNb={setSentencNb}
          sentencNb={sentencNb}
          regenarate={regenarate}
          count={count}
          paraphrase={paraphrase}
          spelling={spelling}
        />
      </div>
      <LanguageDetectModal
        handleClose={handleClose}
        open={openLang}
        message={langModal.message}
        langIcon={langModal?.language?.icon}
        onChek={onCheck}
        error={!langModal?.language}
        setFirstLanguage={setFirstLanguage}
        islength={islength}
        firstLanguage={firstLanguage}
        spelling={spelling}
        translation={translation}
        paraphrase={paraphrase}
        summary={summary}
      />
    </div>
  );
};

export default ServiceInput;
