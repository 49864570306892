import { pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export function SummarizingFn(reponse) {
  return reponse.join("\n");
}

export function fixGrammarErrors(inputText, grammarResponse, betterIndex) {
  const originalText = inputText;
  let correctedText = originalText;
  const errors = grammarResponse.response?.errors;
  if (errors && Array.isArray(errors)) {
    const sortedErrors = [...errors].sort((a, b) => b.offset - a.offset);

    sortedErrors.forEach((error) => {
      const replacement = !error.better[betterIndex]
        ? error.better[0]
        : error.better[betterIndex];
      correctedText =
        correctedText.substring(0, error.offset) +
        replacement +
        correctedText.substring(error.offset + error.length);
    });

    const result = {
      originalText,
      correctedText,
      errors: errors.map((error) => ({
        error: error.bad,
        description: error.description.en,
        correction: error.better.join(", "),
      })),
    };
    return `${result.correctedText} \n 
    ${result?.errors.map((error, index) => {
      return `Erreur ${index + 1}: \n - Erreur: ${
        error.error
      } \n - Description: ${error.description} \n - Correction: ${
        error.correction
      }\n \n`;
    })}`;
  }
}
//

export const readPDFFile = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = async (event) => {
      try {
        const typedArray = new Uint8Array(event.target.result);
        const pdfText = await extractPDFText(typedArray);
        resolve(pdfText);
      } catch (error) {
        reject(error);
      }
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsArrayBuffer(file);
  });
};

export const extractPDFText = async (pdfData) => {
  try {
    const loadingTask = pdfjs.getDocument({ data: pdfData });
    const pdf = await loadingTask.promise;

    const numPages = pdf.numPages; // Get the total number of pages

    let allPageText = ""; // Initialize a variable to store text from all pages

    for (let pageNum = 1; pageNum <= numPages; pageNum++) {
      const textContent = await pdf
        .getPage(pageNum)
        .then((page) => page.getTextContent());
      const pageText = textContent.items.map((item) => item.str).join(" ");
      allPageText += pageText + " "; // Concatenate text from each page
    }

    return allPageText.trim(); // Remove extra spaces at the beginning or end
  } catch (error) {
    throw new Error("Error extracting text from PDF:", error);
  }
};

export function concatenateElementsAtIndex(arrays, index) {
  if (index < 0 || index >= arrays[0].length) {
    return "Invalid index";
  }

  const concatenatedArray = arrays.map(subarray => subarray[index]);
  return concatenatedArray.join(" ");
}

 export function findLanguageByCode(arr, code) {
  const language = arr.find((lang) => lang.codeDetected === code);
  if (language) {
    return { language, message: 'Language is supported' };
  }
  return { language: null, message: 'Language is not supported' };
}